.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.header__title {
  font-size: 50px;
  font-family: "Lora", serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #292b32;
  overflow-y: auto;
  --secondary: rgb(161, 161, 161);
  --divider: #343434;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  justify-content: center;
  width: 100%;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #2f343c;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #393f4a;
}

.screen {
  width: 100%;
  height: 100%;
}

.container {
  max-width: 990px;
  flex: 1 1 100%;
  padding: 45px 25px;
}

h1 {
  font-weight: bold;
  color: white;
  margin: 6px 0 12px;
}

.date {
  color: var(--secondary);
  font-size: 14px;
  text-transform: uppercase;
}

header {
  border-bottom: 1px solid var(--divider);
  position: relative;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h2 {
  color: #fff;
  margin: 8px 0;
}

.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.content-container {
  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;
}

p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}
